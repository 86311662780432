<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Answer each of the questions below for
        <chemical-latex content="ICl4-" />
        molecule:
      </p>

      <p class="mb-2">
        a) What is the total number of valence electrons used to make the Lewis structure for this
        molecule?
      </p>

      <calculation-input
        v-model="inputs.valenceElectrons"
        class="mb-1"
        prepend-text="$\text{Valence Electrons}:$"
        dense
        :disabled="!allowEditing"
      />

      <p class="mb-n3">
        <v-row no-gutters>
          <v-col class="d-flex my-0 pb-0 pt-6 mr-2 pr-0" cols="5">
            b) What is the electron group geometry?
          </v-col>
          <v-col class="d-flex my-0 pb-0 pl-0 ml-0" cols="4">
            <v-select
              v-model="inputs.parentGeometry"
              :items="items"
              item-text="text"
              item-value="value"
              label="Select geometry:"
            >
              <template #item="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
              <template #selection="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
            </v-select>
          </v-col>
        </v-row>
      </p>

      <p class="mb-5">
        <v-row no-gutters>
          <v-col class="d-flex my-0 pb-0 pt-6 mr-2 pr-0" cols="5">
            c) What is the molecular shape?
          </v-col>
          <v-col class="d-flex my-0 pb-0 pl-0 ml-0" cols="4">
            <v-select
              v-model="inputs.shape"
              :items="items"
              item-text="text"
              item-value="value"
              label="Select shape:"
            >
              <template #item="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
              <template #selection="{item}">
                <span class="no-text-transform" v-html="item.text" />
              </template>
            </v-select>
          </v-col>
        </v-row>
      </p>

      <p class="mb-0">
        d) What are the ideal bond angles for this shape/geometry? Select all that apply.
      </p>

      <v-row class="pl-8 mb-2">
        <v-checkbox
          v-for="option in optionsAngles"
          v-model="inputs.bondAngles"
          :key="'pt-4-' + option.value"
          :value="option.value"
          class="pr-8"
        >
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </v-row>

      <p class="mb-2">
        e) For each bond angle, indicate whether the real bond angle is, less than (&lt;), equal to
        (=), or greater than (&gt;) the ideal bond angle(s) selected in part d).
      </p>

      <v-simple-table style="max-width: 300px">
        <thead>
          <tr>
            <th style="font-size: 16px">Angle</th>
            <th style="text-align: center; font-size: 16px">&lt;</th>
            <th style="text-align: center; font-size: 16px">=</th>
            <th style="text-align: center; font-size: 16px">&gt;</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$90^\circ$" />
            </td>
            <td>
              <v-radio-group
                v-model="inputs.deviation90"
                class="pl-1"
                :disabled="!allowEditing || !inputs.bondAngles.includes('90')"
              >
                <v-radio key="lessThan" value="lessThan" />
              </v-radio-group>
            </td>
            <td>
              <v-radio-group
                v-model="inputs.deviation90"
                class="pl-1"
                :disabled="!allowEditing || !inputs.bondAngles.includes('90')"
              >
                <v-radio key="equalTo" value="equalTo" />
              </v-radio-group>
            </td>
            <td>
              <v-radio-group
                v-model="inputs.deviation90"
                class="pl-1"
                :disabled="!allowEditing || !inputs.bondAngles.includes('90')"
              >
                <v-radio key="greaterThan" value="greaterThan" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$109.5^\circ$" />
            </td>
            <td>
              <v-radio-group
                v-model="inputs.deviation109"
                class="pl-1"
                :disabled="!allowEditing || !inputs.bondAngles.includes('109')"
              >
                <v-radio key="lessThan" value="lessThan" />
              </v-radio-group>
            </td>
            <td>
              <v-radio-group
                v-model="inputs.deviation109"
                class="pl-1"
                :disabled="!allowEditing || !inputs.bondAngles.includes('109')"
              >
                <v-radio key="equalTo" value="equalTo" />
              </v-radio-group>
            </td>
            <td>
              <v-radio-group
                v-model="inputs.deviation109"
                class="pl-1"
                :disabled="!allowEditing || !inputs.bondAngles.includes('109')"
              >
                <v-radio key="greaterThan" value="greaterThan" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$120^\circ$" />
            </td>
            <td>
              <v-radio-group
                v-model="inputs.deviation120"
                class="pl-1"
                :disabled="!allowEditing || !inputs.bondAngles.includes('120')"
              >
                <v-radio key="lessThan" value="lessThan" />
              </v-radio-group>
            </td>
            <td>
              <v-radio-group
                v-model="inputs.deviation120"
                class="pl-1"
                :disabled="!allowEditing || !inputs.bondAngles.includes('120')"
              >
                <v-radio key="equalTo" value="equalTo" />
              </v-radio-group>
            </td>
            <td>
              <v-radio-group
                v-model="inputs.deviation120"
                class="pl-1"
                :disabled="!allowEditing || !inputs.bondAngles.includes('120')"
              >
                <v-radio key="greaterThan" value="greaterThan" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$180^\circ$" />
            </td>
            <td>
              <v-radio-group
                v-model="inputs.deviation180"
                class="pl-1"
                :disabled="!allowEditing || !inputs.bondAngles.includes('180')"
              >
                <v-radio key="lessThan" value="lessThan" />
              </v-radio-group>
            </td>
            <td>
              <v-radio-group
                v-model="inputs.deviation180"
                class="pl-1"
                :disabled="!allowEditing || !inputs.bondAngles.includes('180')"
              >
                <v-radio key="equalTo" value="equalTo" />
              </v-radio-group>
            </td>
            <td>
              <v-radio-group
                v-model="inputs.deviation180"
                class="pl-1"
                :disabled="!allowEditing || !inputs.bondAngles.includes('180')"
              >
                <v-radio key="greaterThan" value="greaterThan" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'Question483',
  components: {CalculationInput, ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        valenceElectrons: null,
        parentGeometry: null,
        shape: null,
        bondAngles: [],
        deviation90: null,
        deviation109: null,
        deviation120: null,
        deviation180: null,
      },
      items: [
        {text: 'Bent', value: 'bent'},
        {text: 'Linear', value: 'linear'},
        {text: 'Octahedral', value: 'octahedral'},
        {text: 'See-saw', value: 'seesaw'},
        {text: 'Square planar', value: 'squarePlanar'},
        {text: 'Square pyramidal', value: 'squarePyramid'},
        {text: 'T-shaped', value: 'tshaped'},
        {text: 'Tetrahedral', value: 'tetrahedral'},
        {text: 'Trigonal bipyramidal', value: 'trigBipyramid'},
        {text: 'Trigonal planar', value: 'trigPlanar'},
        {text: 'Trigonal pyramidal', value: 'trigPyramid'},
      ],
      optionsAngles: [
        {text: '$90^\\circ$', value: '90'},
        {text: '$109.5^\\circ$', value: '109'},
        {text: '$120^\\circ$', value: '120'},
        {text: '$180^\\circ$', value: '180'},
      ],
    };
  },
};
</script>
<style scoped></style>
